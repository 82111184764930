/*
 * @Author: liuliwen 
 * @Date: 2020-04-08 16:15:21 
 * @Last Modified by: liuliwen
 * @Last Modified time: 2020-04-09 09:58:35
 */
import axios from 'axios'
// import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  withCredentials: true, // 跨域请求时发送 cookies
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    // store.commit('showLoading')
    // if (store.getters.token) {
    //   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //   config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // store.commit('hideLoading')
    console.log("error", error) // for debug
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
    // store.commit('hideLoading')
    // console.log('interceptors', res)
    // if (res.state != 200 && res.state != 205) {
    //   // Toast.fail(res.msg);
    //   if (res.state === 301) {
    //     window.location.href = res.data;
    //     if (res.state === 500 || res.state === 404) {
    //     // if (res.state === 404) {
    //       // Toast.fail("出问题啦！");
    //       // location.reload()
    //     }
    //     // MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
    //     //   confirmButtonText: '重新登录',
    //     //   cancelButtonText: '取消',
    //     //   type: 'warning'
    //     // }).then(() => {
    //     //   store.dispatch('user/resetToken').then(() => {
    //     //     location.reload() // 为了重新实例化vue-router对象 避免bug
    //     //   })
    //     // })
    //   }
    //   return Promise.reject('error')
    // } else {
    //   return res
    // }
  },
  error => {
    // store.commit('hideLoading')
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
export default service