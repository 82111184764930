import request from '@/utils/request'

export const getList = (page) => {
  return request({
    url: `app/forum/list?title=&page=${page}&count=10&circleId=0&ajax=1`,
    method: 'get'
  })
}


export const getDetails = (id) => {
  return request({
    url: `/app/forum/info?forum_id=${id}&ajax=1`,
    method: 'get'
  })
}

export const getComment = (id) => {
  return request({
    url: `/app/user/forum/comment/list?forum_id=${id}&page=1&count=3&ajax=1`,
    method: 'get'
  })
}