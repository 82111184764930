<template>
  <a-space direction="vertical">
    <a-card title="写出你的想法" :bordered="true" class="column-card">
      <a-textarea placeholder="写出你的想法..." :rows="4" allow-clear />
    </a-card>

    <a-card title="最新" :bordered="true" class="column-card">
      <div
        v-infinite-scroll="loadDataList"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
      >
        <a-skeleton :loading="skeleton" :paragraph="{ rows: 3 }" active avatar>
          <div
            class="idea-container"
            v-for="(item, index) in ideaList"
            :key="index"
          >
            <div class="idea-items">
              <div class="idea-item">
                <div class="idea-item-main">
                  <div
                    class="idea-item-meta"
                    @click="onDetails(item.id, index + 1)"
                  >
                    <div class="avatar">
                      <a-avatar :src="item.user.headerImg" />
                    </div>
                    <div class="idea-item-meta-content">
                      <h4 class="idea-item-meta-title">
                        {{ item.title }}
                      </h4>
                      <div class="idea-item-meta-description">
                        <!-- {{ item.subtitle }} -->
                        {{ item.user.nickName }} / {{ item.createTime }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="idea-item-meta-content"
                    @click="onDetails(item.id, index + 1)"
                  >
                    {{ item.title }}
                  </div>
                  <div></div>
                  <ul class="idea-item-action">
                    <li>
                      <span
                        >助力
                        <a-icon
                          type="star-o"
                          style="margin-right: 8px"
                        />156</span
                      ><em class="action-split"></em>
                    </li>
                    <li>
                      <span
                        >围观
                        <a-icon type="eye" style="margin-right: 8px" />156</span
                      ><em class="action-split"></em>
                    </li>
                    <li>
                      <span @click="onComment(item.id, index + 1)"
                        >献计献策
                        <a-icon type="message" style="margin-right: 8px" />15
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="idea-item-extra">
                  <img
                    width="272"
                    class="img"
                    alt="logo"
                    :src="item.coverImg"
                  />
                </div>
              </div>

              <div v-if="show == index + 1" class="idea-comment">
                <a-timeline>
                  <a-timeline-item
                    v-for="(data, index) in ideaDetails"
                    :key="index"
                  >
                    <a-icon
                      slot="dot"
                      type="clock-circle-o"
                      style="font-size: 20px"
                    />
                    <div class="comment-info">
                      <div class="comment-user">
                        <div>
                          <a-space>
                            <a-avatar :src="item.user.headerImg" :size="26" />
                            <span>{{ data.user.nickName }}</span>
                          </a-space>
                        </div>
                        <div>{{ data.updateTime }}</div>
                      </div>
                      <div class="dialog-h5" v-html="data.detail"></div>
                    </div>
                  </a-timeline-item>
                </a-timeline>
              </div>
              <div
                v-show="visible == index + 1"
                class="idea-comment withPagination"
              >
                <a-spin :spinning="spinning">
                  <a-icon
                    slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin
                  />

                  <a-list
                    class="comment-list"
                    :header="`有 ${ideaComment.length} 条献计献策`"
                    item-layout="horizontal"
                    :data-source="ideaComment"
                  >
                    <a-list-item slot="renderItem" slot-scope="item">
                      <a-comment :author="item.user.nickName">
                        <template slot="avatar">
                          <a-avatar :src="item.user.headerImg" :size="32" />
                        </template>
                        <template slot="actions">
                          <span key="comment-basic-like">
                            <a-tooltip title="赞">
                              <a-icon
                                type="like"
                                :theme="
                                  action === 'liked' ? 'filled' : 'outlined'
                                "
                                @click="like"
                              />
                            </a-tooltip>
                            <span style="padding-left: '8px'; cursor: 'auto'">
                              {{ likes }}
                            </span>
                          </span>
                          <span key="comment-basic-dislike">
                            <a-tooltip title="踩">
                              <a-icon
                                type="dislike"
                                :theme="
                                  action === 'disliked' ? 'filled' : 'outlined'
                                "
                                @click="dislike"
                              />
                            </a-tooltip>
                            <span style="padding-left: '8px'; cursor: 'auto'">
                              {{ dislikes }}
                            </span>
                          </span>
                          <span key="comment-basic-reply-to">回复</span>
                        </template>
                        <div
                          slot="content"
                          class="dialog-h5"
                          v-html="item.detail"
                        ></div>
                        <a-tooltip slot="datetime" :title="item.createTime">
                          <span>{{ item.createTime }}</span>
                        </a-tooltip>
                      </a-comment>
                    </a-list-item>
                  </a-list>
                </a-spin>
              </div>
            </div>
          </div>
          <div v-if="loading && !busy" style="width: 100%;text-align: center;padding:30px 0;">
            <a-spin />
          </div>
        </a-skeleton>
        
      </div>
    </a-card>
  </a-space>
</template>

<script>
import moment from "moment";
import infiniteScroll from "vue-infinite-scroll";
import { getList, getDetails, getComment } from "@/api/index";
export default {
  directives: { infiniteScroll },
  data() {
    return {
      moment,
      visible: false,
      show: false,
      skeleton: false,
      spinning: false,

      busy: false,
      loading: false,

      ideaList: [],
      ideaDetails: [],
      ideaComment: [],

      likes: 0,
      dislikes: 0,
      action: null,

      page:1
    };
  },
  mounted() {
    // this.getList();
  },
  beforeMount() {
    this.fetchData((res) => {
      this.ideaList = res.data.content;
    });
  },
  methods: {
    fetchData(callback) {
      this.skeleton = true;
      getList(this.page).then((res) => {
        console.log(res)
        callback(res);
        this.skeleton = false;
      });
    },
    loadDataList() {
      let data = this.ideaList;
      this.loading = true;
      if (data.length > 20) {
        this.$message.warning("Infinite List loaded all");
        this.busy = true;
        this.loading = false;
        return;
      }
      this.fetchData((res) => {
        // let total=res.data.totalElements
        this.ideaList = data.concat(res.data.content);
        this.loading = false;
        console.log(this.ideaList);
      });
    },
    getList() {
      console.log("loadDataList");
      this.skeleton = true;

      this.busy = true;
      this.loading = true;
      getList().then((response) => {
        let data = response.data.content;
        this.ideaList = this.ideaList.concat(data);
        this.skeleton = false;
        this.loading = false;
        console.log(this.ideaList);
      });
    },

    onDetails(id, index) {
      this.show = index;
      getDetails(id).then((response) => {

        response.data.pop();

        this.ideaDetails = response.data;
        
        // let arr=[...this.ideaDetails].pop()
        // console.log(arr);
      });
    },

    onComment(id, index) {
      this.visible = index;
      this.spinning = true;
      getComment(id).then((response) => {
        console.log(response.data.content);
        this.ideaComment = response.data.content;
        this.spinning = false;
      });
    },
    like() {
      this.likes = 1;
      this.dislikes = 0;
      this.action = "liked";
    },
    dislike() {
      this.likes = 0;
      this.dislikes = 1;
      this.action = "disliked";
    },
  },
};
</script>

<style scoped>
.column-card {
  width: 1000px;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
}

.withPagination {
  overflow: visible;
  border-radius: 2px;
  border: 1px solid #ebebeb;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
}

.idea-container {
  position: relative;
  transition: opacity 0.3s;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 18px 0;
}
.idea-container:first-child {
  padding-top: 0;
}
.idea-item {
  display: flex;
  justify-content: space-between;
  align-items: initial;
}
.idea-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  font-size: 0;
  margin-bottom: 16px;
}

.avatar {
  margin-right: 16px;
}

.idea-item-meta-title {
  margin-bottom: 12px;
  color: #121212;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.idea-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.idea-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 15px;
  line-height: 22px;
}
.idea-item-meta-content {
  word-break: break-all;
  font-size: 15px;
  line-height: 1.7;
  overflow: hidden;
  color: #121212;
}
.idea-item-main {
  cursor: pointer;
  display: block;
  flex: 1;
  width: 0;
}

.idea-item-extra {
  margin-left: 40px;
  width: 272px;
  height: 150px;
}

.idea-item-action {
  margin-top: 16px;
  padding-left: 0;
}
.idea-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.idea-item-action li:first-child {
  padding-left: 0;
}

.action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}

.idea-comment {
  border: 1px solid #e8e8e8;
  padding: 20px;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
  border-radius: 2px;
}

.dialog-h5 >>> img {
  max-width: 100%;
  height: auto;
}
.dialog-h5 >>> p {
  font-size: 15px;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.comment-info {
  border: 1px solid #e8e8e8;
  padding: 20px;
}
.comment-user {
  display: flex;
  align-items: center;
  padding: 0 0 20px;
  justify-content: space-between;
}
</style>
